import React, { useEffect, useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from '@material-ui/icons/Clear';
import ImageIcon from '@material-ui/icons/Image';
import {useDropzone} from 'react-dropzone';
import clsx from "clsx";
import {APP_URL} from "../../config";

const useStyles = makeStyles(theme => ({
    dropZone: {
        flex: 1,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        padding: theme.spacing(3),
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'rgba(0,0,0,0.54)',
        borderStyle: 'dashed',
        color: 'rgba(0,0,0,0.54)',
        outline: 'none',
        transition: 'border .14s ease-in-out',
        '&:hover':{
            borderColor: theme.palette.primary.main,
        },
        '&:hover $dropzoneIcon': {
            color: theme.palette.primary.main
        }
    },
    fileError: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
    },
    dropzoneIcon: {
        color: 'rgba(0,0,0,0.54)',
        transition: 'color .14s ease-in-out',
    },
    iconError: {
        color: theme.palette.error.main
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 0,
        overflow: 'hidden',
        '&:hover $imageRemove': {
            opacity: 1
        }
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
    imageRemove: {
        position: 'absolute',
        display: 'flex',
        opacity: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transition: 'opacity 300ms'
    },
    removeIcon: {
        flex: 1,
        cursor: 'pointer'
    }
}));

const Dropzone = ({ onChange, submitSucceeded, shouldReset = true, originalFileName = null, storageDirectory }) => {

    const classes = useStyles();

    const [files, setFiles] = useState([]);

    const [fileErrorMessage, setFileErrorMessage] = useState('')

    const { getRootProps, getInputProps } = useDropzone({
        accept: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        maxSize: 20000000,
        multiple: false,
        noDrag: true,
        onDrop: acceptedFiles => {
            const files = acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            );
            setFiles(files);
            setFileErrorMessage('');

            if (onChange) {
                onChange(files);
            }
        },
        onDropRejected: (files, event) => {
            handleFileError(files)
        }
    });

    useEffect(() => {

        if(submitSucceeded && shouldReset){
            setFiles([]);
            setFileErrorMessage('');
        }

    }, [submitSucceeded]);


    // Función que se ejecuta cuando se selecciona un archivo inválido
    const handleFileError = (rejectedFiles) => {

        // Actualizar state
        setFiles([]);

        switch (rejectedFiles[0].errors[0].code) {
            case 'file-too-large':
                setFileErrorMessage('El archivo pesa más que el límite de 20MB');
                break;
            case 'file-invalid-type':
                setFileErrorMessage('El tipo de archivo no es válido');
                break;
            default:
                setFileErrorMessage('Ocurrió un error desconocido cargando la imagen');
                break;
        }

        if (onChange) {
            onChange([]);
        }
    };

    const removeFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
    };

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <div>
            <section className={ clsx(classes.dropZone, fileErrorMessage !== '' && classes.fileError) } {...getRootProps()}>

                <ImageIcon className={ clsx(classes.dropzoneIcon, fileErrorMessage && classes.iconError) } fontSize="large" />
                <input {...getInputProps()} />
                {
                    fileErrorMessage !== '' ?
                        <p>{ fileErrorMessage }</p> :
                        <p>{ files.length ? '1 archivo seleccionado' : 'Arrastra o da click para seleccionar un archivo'}</p>
                }
            </section>
        </div>
    );
};

export default Dropzone;
