import React from 'react';
import './App.css';
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {COLOR_PRIMARY, COLOR_SECONDARY} from "./config/colors";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {

  // Tema de Material UI
  const theme = createMuiTheme({
    palette: {
      primary: { main: COLOR_PRIMARY },
      secondary: { main: COLOR_SECONDARY }
    },
    props: {
      MuiTextField: {
        margin: 'normal',
      },
      MuiFormControl: {
        margin: 'normal',
      },
    }
  });

  return <ThemeProvider theme={ theme }>
    <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
            <Switch>
              <Route path="/" exact component={ Dashboard } />
            </Switch>
        </BrowserRouter>
    </SnackbarProvider>
  </ThemeProvider>
}

export default App;
